<template>
  <div class="p-fluid formgrid grid">
    <div class="field col-6 md:col-3">
      <label for="dataAtivacao">Data de Ativação</label>
      <Calendar
        id="dataAtivacao"
        v-model="dataAtivacao"
        dateFormat="dd/mm/yy"
        placeholder="Data de Ativação"
        class="mb-3"
        :disabled="!edicao"
        @input="updateValue('dataAtivacao', dataAtivacao)" />
    </div>
    <div class="col-12">
      <span for="faixas">Faixas de Valores</span>
    </div>
    <div
      v-for="(faixa, index) in initialData.faixas"
      :key="index"
      class="flex flex-row flex-wrap">
      <div class="field col-3 offset-1">
        <label :for="'valorMin'">Valor Mínimo</label>
        <InputNumber
          v-model="faixa.valorMin"
          placeholder="Valor Min"
          :disabled="!edicao"
          :minFractionDigits="2" />
      </div>
      <div class="field col-3">
        <label :for="'valorMax'">Valor Máximo</label>
        <InputNumber
          v-model="faixa.valorMax"
          placeholder="Valor Máx"
          :disabled="!edicao"
          :minFractionDigits="2" />
      </div>
      <div class="field col-3">
        <label :for="'valorCobranca'">Valor de Cobrança</label>
        <InputNumber
          v-model="faixa.valorCobranca"
          placeholder="Valor de Cobrança"
          :disabled="!edicao"
          :minFractionDigits="2" />
      </div>
      <div class="field col-2">
        <Button icon="pi pi-plus" class="p-button-sm mr-1" @click="addFaixa" />
        <Button
          icon="pi pi-minus"
          class="p-button-sm"
          :disabled="!faixas[2]"
          @click="removeFaixa(index)" />
      </div>
    </div>
    <div class="field col-12 md:col-6">
      <label for="observacoes">Observações</label>
      <Textarea
        id="observacoes"
        v-model="observacoes"
        :disabled="!edicao"
        @input="updateValue('observacoes', observacoes)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: () => ({
        faixas: [],
        dataAtivacao: '',
        observacoes: '',
      }),
    },
    edicao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataAtivacao: this.initialData.dataAtivacao,
      faixas: this.initialData.faixas,
      observacoes: this.initialData.observacoes,
    }
  },
  methods: {
    updateValue(field, value) {
      this.$emit('updateData', { field, value })
    },
    addFaixa() {
      this.$emit('addFaixa')
    },
    removeFaixa(index) {
      this.$emit('removeFaixa', index)
    },
  },
}
</script>
