<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <Panel header="Tabela de Precificação" class="mt-3">
          <template #header>
            <div class="header-container">
              <span class="header-title">Tabela de Precificação</span>
              <Button
                v-if="tabelaPrecificacao.length"
                v-tooltip.left="'Inativar Precificação'"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mr-2 jus"
                @click="inativarPrecificacao(tabelaPrecificacao)" />
            </div>
          </template>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="consignataria">Consignatária</label>
              <Dropdown
                id="consignataria"
                v-model="v$.consignataria.$model"
                data-key="id"
                optionLabel="razaoSocial"
                :options="consignatarias"
                :filter="true"
                filterPlaceholder="Procure uma Consignatária"
                :emptyFilterMessage="'Nenhuma encontrada'"
                placeholder="Selecione uma Consignatária">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                    <span>
                      {{ slotProps.value.cnpj }}
                      {{ slotProps.value.razaoSocial }}
                    </span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>

                <template #option="slotProps">
                  <div class="flex align-items-center">
                    <span>
                      {{ slotProps.option.cnpj }}
                      {{ slotProps.option.razaoSocial }}
                    </span>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="field col-6 md:col-6">
              <label for="tipoServico">Tipo de Cálculo</label>
              <Dropdown
                id="tipoServico"
                v-model="v$.tipoServico.$model"
                :options="tiposServicos"
                :disabled="!consignataria"
                placeholder="Selecione um Tipo de Cálculo">
              </Dropdown>
              <span v-if="tipoServico == 1">
                O tipo 1 usa um valor padrão por linha processada
              </span>
              <span v-if="tipoServico == 2">
                O tipo 2 usa um valor padrão e um valor legado por linha
                processada
              </span>
              <span v-if="tipoServico == 3">
                O tipo 3 usa um valor definido para cada faixa de valor da linha
                processada
              </span>
            </div>
          </div>
          <component
            :is="componenteTipo"
            :initialData="precificacaoComponent"
            :edicao="edicao"
            @updateData="handleDataUpdate"
            @addFaixa="addFaixa"
            @removeFaixa="removeFaixa" />
          <Button
            label="Salvar"
            icon="pi pi-save"
            :disabled="!podeSalvar"
            @click="salvarPrecificacao" />
          <Button
            label="Editar"
            class="p-button-warning ml-3"
            icon="pi pi-pencil"
            :disabled="!tabelaPrecificacao.length"
            @click="editarPrecificacao" />
          <span v-if="!isFaixaValida.isValid" class="p-error">
            {{ isFaixaValida.conflito.mensagem }}
          </span>
        </Panel>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TabelaPrecificacaoService from '@/service/TabelaPrecificacaoService'
import ConsignatariaService from '@/service/ConsignatariaService'
import TipoServicoPadrao01 from '@/views/gestao/financa/servicostipos/TipoServicoPadrao01.vue'
import TipoServicoLegado02 from '@/views/gestao/financa/servicostipos/TipoServicoLegado02.vue'
import TipoServicoFaixa03 from '@/views/gestao/financa/servicostipos/TipoServicoFaixa03.vue'
import Precificacao from '@/domain/Precificacao.js'

export default {
  name: 'TabelaPrecificacao',

  components: {
    TipoServicoPadrao01,
    TipoServicoLegado02,
    TipoServicoFaixa03,
  },

  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      precificacaoComponent: {
        dataAtivacao: null,
        valorCobranca: null,
        valorCobrancaPadrao: null,
        valorLegado: null,
        observacoes: null,
        faixas: [
          { valorMin: null, valorMax: null, valorCobranca: null },
          { valorMin: null, valorMax: null, valorCobranca: null },
        ],
      },
      consignataria: null,
      consignatarias: [],
      tipoServico: null,
      tiposServicos: [1, 2, 3],
      edicao: true,
      tabelaPrecificacao: [],
      saving: false,
      precificacaoVigente: null,
    }
  },

  computed: {
    componenteTipo() {
      switch (this.tipoServico) {
        case 1:
          return 'TipoServicoPadrao01'
        case 2:
          return 'TipoServicoLegado02'
        case 3:
          return 'TipoServicoFaixa03'
        default:
          return null
      }
    },
    isFaixaValida() {
      for (let i = 0; i < this.precificacaoComponent.faixas.length - 1; i++) {
        const current = this.precificacaoComponent.faixas[i]
        const next = this.precificacaoComponent.faixas[i + 1]

        const validationResult = this.validaFaixas(current, next)
        if (!validationResult.isValid) {
          return validationResult
        }
      }
      return { isValid: true, conflito: null }
    },

    isTipoServico1Valido() {
      return (
        this.tipoServico === 1 &&
        this.precificacaoComponent.dataAtivacao &&
        this.precificacaoComponent.valorCobranca
      )
    },
    isTipoServico2Valido() {
      return (
        this.tipoServico === 2 &&
        this.precificacaoComponent.dataAtivacao &&
        this.precificacaoComponent.valorCobrancaPadrao &&
        this.precificacaoComponent.valorLegado
      )
    },
    isTipoServico3Valido() {
      return (
        this.tipoServico === 3 &&
        this.isFaixaValida.isValid &&
        this.precificacaoComponent.dataAtivacao
      )
    },
    podeSalvar() {
      return (
        (this.isTipoServico1Valido ||
          this.isTipoServico2Valido ||
          this.isTipoServico3Valido) &&
        !this.saving
      )
    },
  },
  validations() {
    return {
      consignataria: { required },
      tipoServico: { required },
    }
  },

  watch: {
    consignataria(newValue, oldValue) {
      if (newValue !== oldValue && newValue != null) {
        this.carregaPrecificacao()
      }
    },
  },

  created() {
    this.tabelaPrecificacaoService = new TabelaPrecificacaoService(this.$http)
    this.consignatariaService = new ConsignatariaService(this.$http)
  },

  mounted() {
    this.carregaConsignatarias()
  },
  methods: {
    carregaConsignatarias() {
      this.consignatariaService.getConsignatarias().then((res) => {
        this.consignatarias = res.filter(
          (consignataria) => consignataria.tipoCobranca == 'Boleto',
        )
      })
    },

    carregaPrecificacao() {
      this.tabelaPrecificacaoService
        .getServicosConsignataria(this.consignataria.id)
        .then((res) => {
          this.tabelaPrecificacao = res
          this.carregaPorTipo()
        })
    },

    carregaPorTipo() {
      if (!this.tabelaPrecificacao.length) {
        this.resetForm()
        this.edicao = true
        return
      }

      const valorBase = this.tabelaPrecificacao[0]
      this.tipoServico = valorBase.tipoServico
      this.precificacaoComponent.observacoes = valorBase.observacoes
      this.precificacaoComponent.dataAtivacao = this.formatarDataParaExibir(
        valorBase.dataAtivacao,
      )

      switch (valorBase.tipoServico) {
        case 1:
          this.precificacaoComponent.valorCobranca = valorBase.valorCobranca
          break
        case 2:
          this.carregaTipo2()
          break
        case 3:
          this.precificacaoComponent.faixas = this.tabelaPrecificacao.map(
            (faixa) => ({
              valorMin: faixa.valorMin,
              valorMax: faixa.valorMax,
              valorCobranca: faixa.valorCobranca,
            }),
          )
          break
      }
      this.precificacaoVigente = JSON.parse(
        JSON.stringify(this.precificacaoComponent),
      )
      Object.assign(this.precificacaoVigente, {
        tipoServico: valorBase.tipoServico,
      })
      this.edicao = false
    },

    carregaTipo2() {
      const hasLegado = (entry) => entry.legado
      const legadoEntry = this.tabelaPrecificacao.find(hasLegado)
      const padraoEntry = this.tabelaPrecificacao.find(
        (entry) => !hasLegado(entry),
      )

      this.precificacaoComponent.valorLegado = legadoEntry.valorCobranca
      this.precificacaoComponent.valorCobrancaPadrao = padraoEntry.valorCobranca
    },

    validaFaixas(current, next) {
      const { valorMin: currentMin, valorMax: currentMax } = current
      const { valorMin: nextMin, valorMax: nextMax } = next

      const atualMax = parseFloat(currentMax) || 0
      const proxMin = parseFloat(nextMin) || Infinity

      if (atualMax === proxMin) {
        return {
          isValid: false,
          conflito: {
            mensagem: `Erro: As faixas não podem ter valores iguais. Você quis dizer ${(
              atualMax + 0.01
            ).toFixed(2)} ou ${(atualMax - 0.01).toFixed(
              2,
            )} para o valor ${atualMax}?`,
          },
        }
      } else if (atualMax > proxMin) {
        return {
          isValid: false,
          conflito: {
            mensagem: `Erro: Sobreposição de faixas ocorre entre [${
              currentMin || 0
            }, ${currentMax}] e [${nextMin}, ${nextMax || 'Infinito'}].`,
          },
        }
      }

      return { isValid: true }
    },

    editarPrecificacao() {
      this.edicao = !this.edicao
    },

    handleDataUpdate({ field, value }) {
      this.precificacaoComponent[field] = value
    },

    isPrecificacaoDiferente(newValue, oldValue) {
      if (!oldValue) return true
      if (this.tipoServico !== oldValue.tipoServico) return true

      switch (this.tipoServico) {
        case 1:
          return (
            newValue.valorCobranca !== oldValue.valorCobranca ||
            this.formatarDataParaEnvio(newValue.dataAtivacao) !==
              this.formatarDataParaEnvio(oldValue.dataAtivacao)
          )
        case 2:
          return (
            newValue.valorCobrancaPadrao !== oldValue.valorCobrancaPadrao ||
            this.formatarDataParaEnvio(newValue.dataAtivacao) !==
              this.formatarDataParaEnvio(oldValue.dataAtivacao) ||
            newValue.valorLegado !== oldValue.valorLegado
          )

        case 3:
          if (newValue.faixas.length !== oldValue.faixas.length) return true
          return (
            newValue.faixas.some(
              (faixa, index) =>
                faixa.valorMin !== oldValue.faixas[index].valorMin ||
                faixa.valorMax !== oldValue.faixas[index].valorMax ||
                faixa.valorCobranca !== oldValue.faixas[index].valorCobranca,
            ) ||
            this.formatarDataParaEnvio(newValue.dataAtivacao) !==
              this.formatarDataParaEnvio(oldValue.dataAtivacao)
          )
      }

      return false
    },
    preparaNovaTabela() {
      const dataAtivacaoFormatada = this.formatarDataParaEnvio(
        this.precificacaoComponent.dataAtivacao,
      )
      const precificacao = {
        ...new Precificacao(),
        dataAtivacao: dataAtivacaoFormatada,
        consignataria: this.consignataria,
        tipoServico: this.tipoServico,
        observacoes: this.precificacaoComponent.observacoes,
      }
      let novaTabelaPrecificacao = []

      if (this.tipoServico === 1) {
        novaTabelaPrecificacao.push({
          ...precificacao,
          valorCobranca: this.precificacaoComponent.valorCobranca,
        })
      } else if (this.tipoServico === 2) {
        novaTabelaPrecificacao.push({
          ...precificacao,
          valorCobranca: this.precificacaoComponent.valorCobrancaPadrao,
        })
        novaTabelaPrecificacao.push({
          ...precificacao,
          valorCobranca: this.precificacaoComponent.valorLegado,
          legado: true,
        })
      } else if (this.tipoServico === 3) {
        this.precificacaoComponent.faixas.forEach((faixa) => {
          novaTabelaPrecificacao.push({
            ...precificacao,
            valorMin: faixa.valorMin,
            valorMax: faixa.valorMax,
            valorCobranca: faixa.valorCobranca,
          })
        })
      }
      return novaTabelaPrecificacao
    },

    salvarPrecificacao() {
      this.saving = true
      if (
        !this.isPrecificacaoDiferente(
          this.precificacaoComponent,
          this.precificacaoVigente,
        )
      ) {
        this.saving = false
        this.$toast.add({
          severity: 'info',
          summary: 'A precificacação deve ser diferente da vigente.',
          life: 10000,
        })
        return
      }

      let novaTabelaPrecificacao = []
      novaTabelaPrecificacao = this.preparaNovaTabela()

      this.tabelaPrecificacaoService
        .criaServicosConsignataria(novaTabelaPrecificacao)
        .then(() => {
          this.saving = false
          this.editarPrecificacao()
          this.carregaPrecificacao()
          this.$toast.add({
            severity: 'success',
            summary:
              'A Tabela de Serviços da Consignatária foi setada com sucesso',
            life: 10000,
          })
        })
        .catch(() => {
          this.saving = false
          this.$toast.add({
            severity: 'error',
            summary: 'Erro, tente novamente',
            life: 10000,
          })
        })
    },

    inativarPrecificacao(data) {
      const consignatariaId = data[0].consignataria.id
      this.tabelaPrecificacaoService
        .inativarServicosConsignataria(consignatariaId)
        .then(() => {
          this.resetForm()
          this.$toast.add({
            severity: 'success',
            summary:
              'A Tabela de Serviços vigente da Consignatária foi inativada',
            life: 10000,
          })
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Erro, tente novamente',
            life: 10000,
          })
        })
    },

    resetForm() {
      this.tipoServico = ''
      this.tabelaPrecificacao = []
      this.precificacaoComponent = {
        dataAtivacao: null,
        valorCobranca: null,
        valorCobrancaPadrao: null,
        valorLegado: null,
        observacoes: null,
        faixas: [
          { valorMin: null, valorMax: null, valorCobranca: null },
          { valorMin: null, valorMax: null, valorCobranca: null },
        ],
      }
    },

    formatarDataParaExibir(data) {
      if (!data) return ''
      return moment(data).format('DD/MM/YYYY')
    },

    formatarDataParaEnvio(dataString) {
      if (!dataString) return null
      return moment(dataString, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss')
    },

    addFaixa() {
      this.precificacaoComponent.faixas.push({
        valorMin: null,
        valorMax: null,
        valorCobranca: null,
      })
    },

    removeFaixa(index) {
      this.precificacaoComponent.faixas.splice(index, 1)
    },
  },
}
</script>

<style>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-title {
  font-weight: 600;
}
</style>
