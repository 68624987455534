export default class Precificacao {
  constructor(
    id = null,
    servico = '',
    consignataria = {},
    observacoes = '',
    dataAtivacao = null,
    tipoServico = '',
    legado = false,
    dataContrato = null,
    valorCobranca = null,
    valorMin = null,
    valorMax = null,
    ativo = true,
  ) {
    ;(this.id = id),
      (this.servico = servico),
      (this.consignataria = consignataria),
      (this.observacoes = observacoes),
      (this.dataAtivacao = dataAtivacao),
      (this.tipoServico = tipoServico),
      (this.legado = legado),
      (this.dataContrato = dataContrato),
      (this.valorCobranca = valorCobranca),
      (this.valorMin = valorMin),
      (this.valorMax = valorMax),
      (this.ativo = ativo)
  }
}
