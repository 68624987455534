export default class TabelaPrecificacaoService {
  constructor(http) {
    this._http = http
  }

  async getServicosConsignataria(id) {
    const { data } = await this._http.get(`/api/servicos/${id}`)
    return data
  }

  async criaServicosConsignataria(dados) {
    const { data } = await this._http.post(`/api/servicos`, dados)
    return data
  }

  async inativarServicosConsignataria(id) {
    const { data } = await this._http.put(`/api/servicos/${id}`)
    return data
  }
}
