<template>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-3">
      <label for="dataAtivacao">Data de Ativação</label>
      <Calendar
        id="dataAtivacao"
        v-model="dataAtivacao"
        dateFormat="dd/mm/yy"
        placeholder="Data de Ativação"
        class="mb-3"
        :disabled="!edicao"
        @input="updateValue('dataAtivacao', dataAtivacao)" />
    </div>
    <div class="field col-6 md:col-3">
      <label for="valorLegado" class="mr-3">Valor de Cobrança Legado</label>
      <InputNumber
        id="valorLegado"
        v-model="valorLegado"
        placeholder="R$0,00"
        :minFractionDigits="2"
        :disabled="!edicao"
        @input="updateValue('valorLegado', valorLegado)" />
    </div>
    <div class="field col-6 md:col-3">
      <label for="valorCobranca" class="mr-3">Valor de Cobrança Padrão</label>
      <InputNumber
        id="valorCobranca"
        v-model="valorCobrancaPadrao"
        placeholder="R$0,00"
        :minFractionDigits="2"
        :disabled="!edicao"
        @input="updateValue('valorCobrancaPadrao', valorCobrancaPadrao)" />
    </div>
    <div class="field col-12">
      <label for="observacoes">Observações</label>
      <Textarea
        id="observacoes"
        v-model="observacoes"
        :disabled="!edicao"
        @input="updateValue('observacoes', observacoes)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
    edicao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataAtivacao: this.initialData.dataAtivacao || '',
      valorCobrancaPadrao: this.initialData.valorCobrancaPadrao || null,
      valorLegado: this.initialData.valorLegado || null,
      observacoes: this.initialData.observacoes || '',
    }
  },
  methods: {
    updateValue(field, value) {
      this.$emit('updateData', { field, value })
    },
    addFaixa() {
      this.$emit('addFaixa')
    },
    removeFaixa(index) {
      this.$emit('removeFaixa', index)
    },
  },
}
</script>
