<template>
  <div class="p-fluid formgrid grid">
    <div class="field col-6 md:col-3">
      <label for="dataAtivacao">Data de Ativação</label>
      <Calendar
        id="dataAtivacao"
        v-model="dataAtivacao"
        dateFormat="dd/mm/yy"
        placeholder="Data de Ativação"
        class="mb-3"
        :disabled="!edicao"
        @input="updateValue('dataAtivacao', dataAtivacao)" />
    </div>
    <div class="field col-6 md:col-3">
      <label for="valorCobranca" class="mr-3">Valor de Cobrança</label>
      <InputNumber
        id="valorCobranca"
        v-model="valorCobranca"
        placeholder="R$0,00"
        :minFractionDigits="2"
        :disabled="!edicao"
        @input="updateValue('valorCobranca', valorCobranca)" />
    </div>
    <div class="field col-12 md:col-6">
      <label for="observacoes">Observações</label>
      <Textarea
        id="observacoes"
        v-model="observacoes"
        :disabled="!edicao"
        @input="updateValue('observacoes', observacoes)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
    edicao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataAtivacao: this.initialData.dataAtivacao || '',
      valorCobranca: this.initialData.valorCobranca || null,
      observacoes: this.initialData.observacoes || '',
    }
  },
  methods: {
    updateValue(field, value) {
      this.$emit('updateData', { field, value })
    },
  },
}
</script>
